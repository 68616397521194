import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../Layouts/Dashboard/Index";
import ParticipantsDataTable from "../../../Components/Table/Participants/ParticipantsDataTable";
import {
  getParticipants,
  updateParticipantMailStatus,
  uploadBlobToStorage,
} from "../../../hooks/useAddParticipants";
import { ParticipantsList } from "../../../types/common";
import LoadingState from "../../../Components/Loading";
import CustomBreadcrumb from "../../../Components/custom-breadcrumb/CustmBreadcrumb";
import BgMail from "../../../assets/mail.jpg";
import html2canvas from "html2canvas";
import emailjs from "@emailjs/browser";
import MyModal from "../../../Components/Modal";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { ref } from "firebase/storage";

const ParticipantsPage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingCapture, setLoadingCapture] = useState(false);
  const [loadingQr, setLoadingQr] = useState(false);
  const [loadingMail, setLoadingMail] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [blobImage, setBlobImage] = useState<any[]>([]);
  const [snapshots, setSnapshots] = useState<any[]>([]);
  const [participants, setParticipants] = useState<
    ParticipantsList[] | undefined
  >([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<{ success: boolean; message: string }>(
    { success: false, message: "" }
  );
  const [uploadProgress, setUploadProgress] = useState<string>();

  const [disableMailButton, setDisableMailButton] = useState(false);
  const [refreshParticipants, setRefreshParticipants] = useState<number>(1);

  useEffect(() => {
    setUploadProgress(undefined);
    setLoading(true);
    getParticipants().then((res) => {
      setLoading(false);
      setParticipants(res.data as ParticipantsList[]);
    });
  }, [refreshParticipants]);

  const captureSnapshots = async () => {
    setLoadingCapture(true);
    let snapshotUrls: any[] = [];

    for (const item of blobImage) {
      const element = document.getElementById(`email-template=${item.id}`);

      if (element) {
        // Ensure the element is visible before capturing
        element.style.display = "block";

        // Allow time for the DOM to update before capturing the snapshot
        await new Promise((resolve) => setTimeout(resolve, 100));

        const canvas = await html2canvas(element);
        const blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve)
        );

        if (blob) {
          const url = URL.createObjectURL(blob);
          snapshotUrls.push({
            email: item.email,
            snapshotUrl: url,
            id: item.id,
          });
        }

        // Hide the element after capturing
        element.style.display = "none";
      }
    }

    setSnapshots(snapshotUrls);
    setLoadingCapture(false);
  };

  useEffect(() => {
    if (participants) {
      const fetchQRCodes = async () => {
        setLoadingQr(true);
        let blobUrls: any[] = [];

        for (const participant of participants.filter(
          (item) => item.invitationImageUrl === undefined
        )) {
          const response = await fetch(
            `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${participant.id}`
          );

          const blob = await response.blob();
          const downloadUrl = URL.createObjectURL(blob);

          const item = {
            email: participant.email,
            url: downloadUrl,
            id: participant.id,
          };
          blobUrls.push(item);
        }

        setBlobImage(blobUrls);
        setLoadingQr(false);
      };

      fetchQRCodes();
    }
  }, [participants]);

  useEffect(() => {
    if (
      blobImage.length ===
      participants?.filter((item) => item.invitationImageUrl === undefined)
        .length
    ) {
      captureSnapshots();
    }
  }, [blobImage]);

  const uploadSnapshots = async () => {
    setLoading(true);
    await uploadBlobToStorage(snapshots, handleProgress).then((res) => {
      setLoading(false);
      setRefreshParticipants(refreshParticipants + 1);
    });
  };

  const handleProgress = (progress: string, index: number) => {
    setUploadProgress((prevProgress) => {
      const newProgress = `${progress}, ${index + 1} of ${snapshots.length}`;
      return newProgress;
    });
  };
  const sendEmail = async () => {
    setLoadingMail(true);
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID as string;
    const templateId = process.env.REACT_APP_MAIL_TEMPLATEID as string;
    const publicKey = process.env.REACT_APP_MAIL_PUBLIC_KEY as string;
    if (participants) {
      for (const item of participants.filter((item) => item.email !== "")) {
        const templateParams = {
          subject: "An Exclusive Invitation Awaits You by TVS Lanka",
          message: item.invitationImageUrl,
          to_email: item.email,
        };
        await emailjs
          .send(serviceId, templateId, templateParams, publicKey)
          .then(async (response) => {
            await updateParticipantMailStatus(String(item.id)).then((res) => {
              setOpen(true);
              setMessage({
                success: true,
                message: "Invitations Sent Successfully",
              });
            });
          })
          .catch((error) => {
            console.log(error);
            setOpen(true);
            setMessage({
              success: false,
              message: "Something went wrong while sending the invitaions",
            });
          })
          .finally(() => {
            setLoadingMail(false);
            setRefreshParticipants(refreshParticipants + 1);
          });
      }
    }
  };

  const sendMailButtonState = () => {
    if (participants) {
      const participantsWithQr = participants.filter(
        (item) =>
          item.invitationImageUrl && item.email !== "" && item.invited !== true
      );

      setDisableMailButton(participantsWithQr.length === 0);
    } else {
      setDisableMailButton(true);
    }
  };

  useEffect(() => {
    sendMailButtonState();
  }, [participants]);

  const zipAndDownloadImages = async (participants: ParticipantsList[]) => {
    setLoadingDownload(true);
    const zip = new JSZip();

    for (const participant of participants) {
      try {
        if (participant.invitationImageUrl) {
          const uniqueUrl = `${
            participant.invitationImageUrl
          }?cache_bust=${new Date().getTime()}`;

          const response = await axios.get(uniqueUrl, {
            responseType: "blob",
          });
          zip.file(`${participant.code+" "+participant.name}.png`, response.data);
        }
      } catch (error) {
        console.error(
          `Error downloading image for ${participant.name}:`,
          error
        );
      }
    }

    const content = await zip.generateAsync({ type: "blob" });
    setLoadingDownload(false);
    saveAs(content, "participants_images.zip");
  };

  return (
    <DashboardLayout>
      <MyModal isOpen={open} setIsOpen={setOpen}>
        <div className="text-center">
          <div className="py-4">
            <p
              className={`${
                message.success ? "text-green-500" : "text-red-500"
              }`}
            >
              {message.message}
            </p>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                setOpen(false);
              }}
              className={`focus:outline-none text-white ${
                message.success
                  ? "bg-green-700 hover:bg-green-800"
                  : "bg-red-500 hover:bg-red-800"
              } font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2`}
            >
              Close
            </button>
          </div>
        </div>
      </MyModal>
      {(loading ||
        loadingCapture ||
        loadingQr ||
        loadingMail ||
        loadingDownload) && <LoadingState />}
      {uploadProgress && loading && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          {uploadProgress}
        </div>
      )}
      <div className="pt-10">
        <CustomBreadcrumb
          heading="Participants"
          links={[
            { name: "Dashboard", path: "/dashboard" },
            { name: "Participants", path: "/dashboard/participants" },
          ]}
        />
        <button
          onClick={uploadSnapshots}
          disabled={blobImage.length === 0}
          className="disabled:bg-slate-400 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 me-2 mb-2"
        >
          Generate Invitations
        </button>
        <button
          disabled={disableMailButton}
          onClick={() => {
            sendEmail();
          }}
          type="button"
          className={`disabled:bg-gray-400 px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300`}
        >
          <svg
            className="w-3 h-3 text-white me-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 16"
          >
            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
          </svg>
          Send Invitaions
        </button>{" "}
        {participants &&
          participants.filter((item) => item.invitationImageUrl).length > 0 && (
            <button
              onClick={() => zipAndDownloadImages(participants)}
              className="space-x-2 px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                />
              </svg>
              <span>Download All</span>
            </button>
          )}
        <div className="w-full pt-10">
          <ParticipantsDataTable data={participants} />
        </div>
        {blobImage &&
          blobImage.map((item: any, idx: number) => (
            <div
              className="relative w-[500px] hidden overflow-auto"
              key={idx}
              id={`email-template=${item.id}`}
            >
              <img
                alt="mail"
                src={item.url}
                className="absolute top-[115px] right-12 scale-75"
              />
              <img alt="mail" src={BgMail} />
            </div>
          ))}
      </div>
    </DashboardLayout>
  );
};

export default ParticipantsPage;
