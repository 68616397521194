import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../Layouts/Dashboard/Index";
import CustomBreadcrumb from "../../../Components/custom-breadcrumb/CustmBreadcrumb";
import AttendanceDataTable from "../../../Components/Table/attendance/AttendanceDataTable";
import { getParticipantsAttendance } from "../../../hooks/useAddParticipants";
import { ParticipantsList } from "../../../types/common";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import LoadingState from "../../../Components/Loading";

const AttendancePage = () => {
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState<
    ParticipantsList[] | undefined
  >([]);
  useEffect(() => {
    setLoading(true);
    getParticipantsAttendance().then((res) => {
      setParticipants(res.data as ParticipantsList[]);
      setLoading(false);
    });
  }, []);

  const getClockinDate = (date: any) => {
    if (!date) return "Not Clocked In";
    return moment(new Date(date.seconds * 1000)).format("YYYY-MM-DD hh:mmA");
  };

  const exportToExcel = () => {
    if (participants) {
      const worksheet = XLSX.utils.json_to_sheet(
        participants.map((item) => {
          const { id, ...rest } = item;
          const code = rest.code;
          return { code, ...rest, clockedIn: getClockinDate(rest.clockedIn) };
        })
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `export.xlsx`);
    }
  };

  return (
    <DashboardLayout>
      <div className="pt-10">
        {loading && <LoadingState />}
        <CustomBreadcrumb
          heading="Attendance"
          links={[
            { name: "Dashboard", path: "/dashboard" },
            { name: "Attenfance", path: "/dashboard/attendance" },
          ]}
        />

        <div className="pt-2">
          <button
            onClick={() => exportToExcel()}
            className="space-x-2 px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
              />
            </svg>
            <span>Export an Excel</span>
          </button>
          <div className="pt-10">
            <AttendanceDataTable data={participants} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AttendancePage;
