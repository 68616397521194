import Check from "../assets/svg/nav/check";
import ExcelSvg from "../assets/svg/nav/Exxcel";
import Finder from "../assets/svg/nav/Finder";
import Generate from "../assets/svg/nav/Generate";
import HomeSvg from "../assets/svg/nav/Home";
import { NavigationItem } from "../types/Navigation";

export const NavigationItems: NavigationItem[] = [
  {
    icon: <HomeSvg />,
    href: "/dashboard/",
    title: "Dashboard",
  },
  {
    icon: <ExcelSvg />,
    href: "/dashboard/import",
    title: "Import",
  },
  {
    icon: <Generate />,
    href: "/dashboard/participants",
    title: "Participants",
  },
  {
    icon: <Finder />,
    href: "/dashboard/scan",
    title: "Scan",
  },
  {
    icon: <Check />,
    href: "/dashboard/attendance",
    title: "Attendance",
  },
];
