import React, { useEffect, useRef, useState } from "react";
import { DashboardLayout } from "../../../Layouts/Dashboard/Index";
import QrScanner from "qr-scanner";
import { getParticipantById } from "../../../hooks/useAddParticipants";
import { ParticipantsList } from "../../../types/common";
import MyModal from "../../../Components/Modal";
import LoadingState from "../../../Components/Loading";
import moment from "moment";
import SuccessAudio from "../../../assets/audio/success.mp3";
import WarningAud from "../../../assets/audio/warning.mp3";

import WelcomeBg from "../../../assets/video/background.mp4";
import SuccessBg from "../../../assets/video/success.mp4";
import QrFrame from "../../../assets/qrframe.png";
import Nholder from "../../../assets/nameholder.png";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

const ScanQrPage = () => {
  const [loading, setLoading] = useState(false);
  const [qrData, setQrData] = useState<string | undefined>();
  const [participant, setParticipant] = useState<
    ParticipantsList | null | undefined
  >(null);
  const [message, setMessage] = useState<{ success: boolean; message: string }>(
    { success: false, message: "" }
  );
  const [isClockedIn, setIsClockedIn] = useState<boolean>(false);

  const [open, setOpen] = useState(false);

  const [openError, setOpenError] = useState(false);

  const video = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const welcomeVideoRef = useRef<HTMLVideoElement>(null);
  const successVideoRef = useRef<HTMLVideoElement>(null);

  const [qrScanner, setQrScanner] = useState<QrScanner>();
  const [show, setShow] = useState(false);

  async function close() {
    qrScanner?.destroy();
    setQrScanner(undefined);
  }

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = 5;
      videoElement.play();
    }
  }, [participant]);

  useEffect(() => {
    const welcomeVideo = welcomeVideoRef.current;
    const successVideo = successVideoRef.current;

    if (welcomeVideo) {
      welcomeVideo.play().catch((err) => {
        console.error("Error playing welcome video:", err);
      });
    }

    if (successVideo) {
      successVideo.load();
    }
  }, []);

  useEffect(() => {
    if (video.current) {
      const qrScanner = new QrScanner(
        video.current,
        (result) => handleScan(result),
        {
          highlightScanRegion: true

        }
      );
      qrScanner.setGrayscaleWeights(76, 153, 25, true);
      qrScanner
        .start()
        .then(() => {
          setQrScanner(qrScanner);
        })
        .catch((err) => {
          console.error(
            "Camera permission denied or another error occurred:",
            err
          );
          // Handle the case where permission is denied
          alert(
            "Camera access is required to scan the QR code. Please enable camera permissions in your browser."
          );
          // Optionally, you can display a UI message instead of an alert
        });

      return () => {
        qrScanner.stop();
        qrScanner.destroy();
        setQrScanner(undefined);
      };
    }
  }, []);

  function handleScan(result: QrScanner.ScanResult) {
    setQrData(result.data);
    qrScanner?.destroy();
    setQrScanner(undefined);
  }

  const getUserDetails = async (data: string) => {
    setLoading(true);
    await getParticipantById(data)
      .then((res) => {
        if (res?.success) {
          setIsClockedIn(res?.isClockedIn);
          setParticipant(res.data as ParticipantsList);
          if (res.isClockedIn) {
            const warningAudio = new Audio(WarningAud);
            warningAudio.play().catch((err) => {
              console.error("Warning audio failed to play:", err);
            });
            setOpenError(true);
            setTimeout(() => {
              setOpenError(false);
              setParticipant(null);
            }, 5000);
          } else {
            setTimeout(() => {
              setShow(true);
            }, 4000);
            if (successVideoRef.current) {
              successVideoRef.current.currentTime = 5;
              successVideoRef.current.play();
            }
            const successAudio = new Audio(SuccessAudio);
            successAudio.play().catch((err) => {
              console.error("Success audio failed to play:", err);
            });
          }
        } else {
          setMessage({
            success: false,
            message: "There is no participant with that ID",
          });
          setOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (qrData) {
      setOpen(false);
      setShow(false);
      setParticipant(null);
      setMessage({ success: false, message: "" });
      getUserDetails(qrData);
    }
  }, [qrData]);

  const getClockinDate = (date: any) => {
    if (!date) return "Not Clocked In";
    return moment(new Date(date.seconds * 1000)).format(
      "YYYY/MM/DD - hh : mm A"
    );
  };

  function calculateBottomPosition(screenHeight: number) {
    const minScreenHeight = 616;
    const maxScreenHeight = 832;
    const minBottom = 86;
    const maxBottom = 140;

    // Calculate the ratio of the screen height to the range
    const ratio =
      (screenHeight - minScreenHeight) / (maxScreenHeight - minScreenHeight);

    // Apply the ratio to the bottom value range
    const bottomPosition = minBottom + ratio * (maxBottom - minBottom);

    return bottomPosition;
  }

  const bottom = calculateBottomPosition(useWindowDimensions().height);
  return (
    <DashboardLayout isHidden={true}>
      {/* {loading && <LoadingState />} */}
      {/* <audio src={require("./src/assets/audio/success.mp3")}></audio> */}
      <MyModal isOpen={loading} setIsOpen={setLoading} isTransparent>
        <div className="text-center ava-meridian-text">
          <div className="text-4xl pt-3 pb-5 text-white whitespace-nowrap animate-pulse">
            LOADING . . .
          </div>
        </div>
      </MyModal>
      <MyModal isOpen={openError} setIsOpen={setOpenError} isTransparent>
        <div className="text-center ava-meridian-text">
          <div className="text-5xl pt-3 pb-5 text-red-400 whitespace-nowrap">
            Already Participated
          </div>
          <span className="text-white">
            {getClockinDate(participant?.clockedIn)}
          </span>
        </div>
      </MyModal>
      <MyModal isOpen={open} setIsOpen={setOpen}>
        <div className="text-center w-[300px]">
          <div className="py-4">
            <p
              className={`${
                message.success ? "text-green-500" : "text-red-500"
              }`}
            >
              {message.message}
            </p>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                setOpen(false);

                setQrData(undefined);
              }}
              className={`focus:outline-none text-white ${
                message.success
                  ? "bg-green-700 hover:bg-green-800"
                  : "bg-red-500 hover:bg-red-800"
              } font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2`}
            >
              Closes
            </button>
          </div>
        </div>
      </MyModal>
      <div className="pt-16 h-full w-full relative">
        <video
          ref={welcomeVideoRef}
          src={WelcomeBg}
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-fill"
        />

        <video
          ref={successVideoRef}
          src={SuccessBg}
          muted
          className="absolute top-0 left-0 w-full h-full object-fill"
          style={{ display: participant ? "block" : "none" }}
        />

        <div className="flex h-full relative">
          <div
            className="relative h-[78%] w-[36%] mt-2 ml-[7%]"
            style={{
              backgroundImage: `url(${QrFrame})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <video
              ref={video}
              className="w-full h-full"
              style={{
                scale: "88%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                overflow: "hidden",
                paddingRight: "30px",
                paddingLeft: "30px",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            ></video>
          </div>
          <div
            className={`w-full relative pt-[63vh] ava-meridian-text text-white transition-opacity ease-in ${
              show ? "duration-1000 opacity-100" : "duration-0 opacity-0"
            }`}
          >
            <div
              className={`text-center w-full`}
              style={{
                bottom: bottom,
                backgroundImage: `url(${Nholder})`,
              }}
            >
              <div className="pt-2" style={{ letterSpacing: 2, fontSize: 52 }}>
                {participant?.name}
              </div>
              <div
                className={`flex flex-col justify-center items-center absolute w-full`}
                style={{ fontWeight: 200, letterSpacing: 10, fontSize: 32 }}
              >
                {participant?.dealershipName}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ScanQrPage;
