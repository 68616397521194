import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import DashboardPage from "./pages/dashboard";
import ImportPage from "./pages/dashboard/import";
import AuthPage from "./pages/auth";
import { AuthContextProvider } from "./context/AuthContext";
import { Protected } from "./routes/Protected";
import ParticipantsPage from "./pages/dashboard/participants";
import ScanQrPage from "./pages/dashboard/scan";
import AttendancePage from "./pages/dashboard/attendance";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthPage />,
    },
    {
      path: "/dashboard",
      element: (
        <Protected>
          <DashboardPage />
        </Protected>
      ),
    },
    {
      path: "/dashboard/import",
      element: (
        <Protected>
          <ImportPage />
        </Protected>
      ),
    },
    {
      path: "/dashboard/participants",
      element: (
        <Protected>
          <ParticipantsPage />
        </Protected>
      ),
    },
    {
      path: "/dashboard/scan",
      element: (
        <Protected>
          <ScanQrPage />
        </Protected>
      ),
    },
    {
      path: "/dashboard/attendance",
      element: (
        <Protected>
          <AttendancePage />
        </Protected>
      ),
    },
  ]);
  return (
    <AuthContextProvider>
      <RouterProvider router={router}></RouterProvider>
    </AuthContextProvider>
  );
}

export default App;
