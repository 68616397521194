import { SideNavigation } from "../../Components/SideNavigation";
import { DashboardProps } from "../../types/Dashboard";

const Dashboard = ({ children, isHidden }: DashboardProps) => {
  return (
    <div className="flex">
      <SideNavigation isHidden={isHidden} />
      <div
        className={`  w-full pr-[10px] ${
          isHidden ? "" : "pl-[70px] sm:pl-72 sm:pr-7"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Dashboard;
