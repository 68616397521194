import { signOut } from "firebase/auth";
import { NavigationItems } from "../../constants/Navigation";
import Navitem from "./NavItem/Navitem";

import { useLocation, useParams, useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase-config";

const SideNavigationBar = ({ isHidden }: { isHidden?: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const normalizePath = (path: string) => {
    return path.endsWith("/") && path.length > 1 ? path.slice(0, -1) : path;
  };

  return (
    <div className="min-h-screen flex flex-col flex-shrink-0 antialiased bg-gray-50 text-gray-800">
      <div
        className={`fixed ${
          isHidden ? "hidden" : "flex"
        } flex-col top-0 left-0 bg-[#070738] h-full  w-fit sm:w-64`}
      >
        <div className="hidden sm:flex items-center justify-center h-14 border-b">
          <div>Event Management</div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-1">
            {NavigationItems.map((navItem, idx) => (
              <Navitem
                key={idx}
                icon={navItem.icon}
                href={navItem.href}
                title={navItem.title}
                selected={
                  normalizePath(location.pathname) ===
                  normalizePath(navItem.href)
                }
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideNavigationBar;
